import React from 'react';

// l18n
import '../i18l';
import i18next from 'i18next';

const Intro = () => {
	return (
		<section className='intro'>
      <h1>{i18next.t('application_title')}</h1>
      <p>{i18next.t('application_description')}</p>
    </section>
	);
}

export default Intro;