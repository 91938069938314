import React from 'react';
// import TextInput from './TextInput';
import SingleUsage from './SingleUsage';
import {formatPrice, formatDate} from './../helpers/Helpers';

// l18n
import '../i18l';
import i18next from 'i18next';

/**
 * This is one single "usage", for example a birth in the marina. This displays all key info to the user
 * and offers renewal or cancellation options.
 */
const Usage = ({data}) => {
	let {
		id, 
		name, 
		type, 
		area, 
		startDate, 
		endDate = null, 
		primary = null, 
		secondary = null
	} = data;

	return (
		<div data-usage-id={id} className='usages__single'>
			<div className='usages__single__name col'>
				<p><span className='label'>{i18next.t('usage_name')}:</span> {name}</p>
				<p><span className='label'>{i18next.t('usage_type')}:</span> {type}</p>
				<p><span className='label'>{i18next.t('usage_area')}:</span> {area}m<sup>2</sup></p>
			</div>

			{/* Package start and end dates */}
			<div className='usages__single__dates col'>
				<p><span className='label'>{i18next.t('usage_start_date')}:</span> {formatDate(startDate)}</p>
				{endDate !== null && <p><span className='label'>{i18next.t('usage_end_date')}:</span> {formatDate(endDate)}</p>}
			</div>

			{/* Allow user to select renewal or cancellation */}
			<div className='usages__single__renewal col'>
				<p className='label'>{i18next.t('usage_renewal_options_heading')}</p>
				{/*primary ? ( 
					<SingleUsage renewalId={id} priceId={primary.id} label={i18next.t('usage_renewal_annual', {price: formatPrice(primary.amount)})} /> 
				) : ( 
					<SingleUsage renewalId={id} priceId='' label={i18next.t('please_contact_the_port')} /> 
				)*/}
				{secondary ? ( 
					<SingleUsage renewalId={id} priceId={secondary.id} label={i18next.t('usage_renewal_monthly', {price: formatPrice(secondary.amount)})} /> 
				) : ( 
					<SingleUsage renewalId={id} priceId='' label={i18next.t('please_contact_the_port')} /> 
				)}
				<label htmlFor={id + '-cancel'}>
					<span className='radio-label-text'>{i18next.t('usage_do_not_renew')}</span>
					<input name={'item-' + id} type='radio' value='cancel' id={id + '-cancel'} />
				</label>
			</div>
		</div>
	);
}

export default Usage;