export default {
  translation: {
    'next': 'Siguiente',
    'previous': 'Anterior',
    'please_wait': 'Por favor espera',
    'querying_data': 'Por favor, espere un momento, estamos consultando sus datos.',
    'enter_code': 'Por favor ingrese su DNI para comenzar',
    'your_tax_code': 'Su DNI',
    'tax_code_not_found': 'Lo sentimos, no pudimos encontrar su DNI en nuestro sistema. Por favor revisa e intenta de nuevo.',
    'tax_code_not_valid': 'Lo sentimos, su DNI no es válido.',
    'sms_auth_code': 'Código de autenticación SMS',
    'no_auth_code': 'Debe introducir su código de autenticación. Si no ha recibido su código, espere 5 minutos, solicite un nuevo código o contáctenos para obtener ayuda.',
    'sms_sent': 'Hemos enviado un código de acceso de autenticación a través de SMS. Por favor ingrese su código a continuación.',
    'sms_not_valid': 'El código de autenticación de SMS no es válido.',
    'sms_code_expired': 'El código de autenticación de SMS ha caducado. Por favor, solicite un nuevo código.',
    'preferences_updated': 'Sus preferencias se han actualizado con éxito!',
    'internal_error': 'Error interno. Por favor, póngase en contacto con nosotros para obtener ayuda.',
    // 'usages_found': 'Elige una opción para cada una de sus suscripciones actuales',
    'no_usages_notice': 'Lo sentimos, no pudimos encontrar ningún dato relacionado con su cuenta. Por favor, póngase en contacto con nosotros para obtener ayuda o intente de nuevo.',
    'update_iban': 'Actualiza su IBAN (opcional)',
    'usages_found': 'Seleccione un valor para cada una de sus suscripciones actuales.',
    'usage_name': 'Nombre',
    'usage_type': 'Tipo',
    'usage_area': 'Área',
    'usage_start_date': 'Fecha de inicio',
    'usage_end_date': 'Fecha final',
    'usage_renewal_options_heading': 'Opciones de renovación',
    'usage_renewal_annual': 'Renovar por {{price}} por año',
    'usage_renewal_monthly': 'Renovar por {{price}} por mes',
    'usage_do_not_renew': 'Cancelar (no renovar)',
    'iban_label': 'IBAN',
    'iban_heading': 'Número de cuenta bancaria internacional (IBAN)',
    'update_button': 'Actualizar información',
    'prefences_updated': 'Gracias, hemos actualizado su cuenta.',
    'menu': 'Menú',
    'application_title': 'Port Ginesta Renovación',
    'application_description': 'Utilice el siguiente formulario para renovar o cancelar su (s) contrato (s) en Port Ginesta.',
    'menu_item_home_url': 'http://www.portginesta.com/es',
    'menu_item_home': 'INICIO',
    'menu_item_location_and_access_url': 'http://www.portginesta.com/es/situacion',
    'menu_item_location_and_access': 'SITUACIÓN Y ACCESOS',
    'menu_item_general_services_url': 'http://www.portginesta.com/es/servicios-generales',
    'menu_item_general_services': 'SERVICIOS GENERALES',
    'menu_item_nautical_services_url': 'http://www.portginesta.com/es/servicios-nauticos',
    'menu_item_nautical_services': 'SERVICIOS NAÚTICOS',
    'menu_item_leisure_url': 'http://www.portginesta.com/es/tiempo-de-ocio',
    'menu_item_leisure': 'TIEMPO DE OCIO',
    'menu_item_news_url': 'http://noticies.portginesta.com/',
    'menu_item_news': 'NOTICIAS',
    'please_contact_the_port': 'Renovar (precio a consultar con el puerto)',
  }
};