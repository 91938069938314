import React, { Component } from 'react';

// API calls.
import { GinestaApiCalls } from './api/PortGinestaApi';

// Styling.
import './sass/index.scss'; // Main SASS file.

// Components.
import NavigationButton from './components/NavigationButton';
import UserUsages from './components/UserUsages';
import TextInput from './components/TextInput';
import LanguageSwitcher from './components/LanguageSwitcher';
import PrimaryNavigation from './components/PrimaryNavigation';
import FooterSection from './components/FooterSection';
import Logo from './components/Logo';
import Intro from './components/Intro';

// Assets.
import check from './assets/check.svg';

// L18n.
import LanguageCodes from './l18n/languageCodes';
import './i18l';
import i18next from 'i18next';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doingAjax: false, // Track if we're making a request so we can block certain actions.
      statusMessage: 'enter_code', // Used for CSS styling in the notice box e.g warnings/errors, guidance.
      statusMessageType: '', // Controls visual theme of status message such as notice or error.
      userToken: null, // Auth JWT token for calling the API.
      userAuthCode: '', // Auth code sent via SMS to the user.
      userTaxCode: '', // User ID code for lookup.
      userIBAN: '', // IBAN bank number.
      usages: [], // Store the current user's data when it's returned by the API.
      cultureCode: LanguageCodes.defaultLanguage,
      step: 0, // The current step in the process. Used to control which elements are displayed to the user.
      showMenu: false, // Should the mobile menu be visible.
    };
  }

  // Method to validate a user by tax code.
  validateUser = event => {
    // this._nextStep();
    // return;

    // Check we have a value from the user.    
    this._startAjax();

    this.setState(state => ({
      statusMessage: 'please_wait',
      statusMessageType: 'waiting',
    }));

    // Make the API call and handle the response.
    GinestaApiCalls.validateUser(event, this.state.userTaxCode)
      .then((response) => {
        console.log(response);

        switch(response.data.ResponseCodeValue) {
          // Request was successful.
          case 'VALID':
            this.setState({
              statusMessage: 'sms_sent',
              statusMessageType: 'notice',
            }, this._nextStep());
            break;

          // Code not found.
          case 'NOT_FOUND':
            this.setState({
              statusMessage: 'tax_code_not_found',
              statusMessageType: 'error',
            });
            break;

          // Not valid.
          case 'NOT_VALID':
            this.setState({
              statusMessage: 'tax_code_not_valid',
              statusMessageType: 'error',
            });
            break;

          // Issue sending code.
          case 'SENDING_CODE_ERROR':
            this.setState({
              statusMessage: 'internal_error',
              statusMessageType: 'error',
            });
            break;

          default: break;
        }
      })
      .catch((response) => {
        console.log('Error ' + response);

        this.setState((state) => ({
          statusMessage: 'internal_error',
          statusMessageType: 'error',
        }));
      })
      .finally(() => {
        this._endAjax();
      });
  };

  // Auth user using the tax code and SMS pass code.
  authenticateUser = event => {
    let {userTaxCode, userAuthCode} = this.state;

    // Check we have a value to work with.
    if (userAuthCode === '') {
      this.setState({
        statusMessage: 'no_auth_code',
        statusMessageType: 'error'
      });

      return;
    }
    
    this._startAjax();

    // Make the API call and handle the response.
    GinestaApiCalls.authenticateUser(event, userTaxCode, userAuthCode)
      .then((response) => {
        console.log(response);

        switch(response.data.ResponseCodeValue) {
          // Request was successful.
          case 'VALID':
            this.setState((state) => ({
              userToken: response.data.Token,
            }), this._nextStep());

            // Now we have a token we can query the API for the user's data.
            this._getUsages();

            break;

          case 'NOT_VALID':
            this.setState(state => ({
              statusMessage: 'sms_not_valid',
              statusMessageType: 'error',
            }));
            break;  

          case 'EXPIRED':
            this.setState(state => ({
              statusMessage: 'sms_code_expired',
              statusMessageType: 'error',
            }));
            break;  

          default: break;
        }
      })
      .catch((response) => {
        this.setState((state) => ({
          statusMessage: 'internal_error',
          statusMessageType: 'error',
        }));
      })
      .finally(() => {
        this._endAjax();
      });
  };

  // Called when the form with renewal options is submit.
  formSubmit = event => {
    event.preventDefault();

    let renewalData = [];

    // Check we have all the data we need.
    let formElement = event.target;
    for (let i = 0, l = formElement.length; i < l; i++) {
      let formInput = formElement[i];

      if (formInput.checked) {
        // Get the ID param from the name attr.
        let data = {};

        data['Id'] = parseInt(formInput.name.replace('item-', ''));
        
        if (formInput.value === 'cancel') {
          data['Renew'] = false;
        } else {
          data['PriceId'] = formInput.value ? parseInt(formInput.value) : null;
          data['Renew'] = true;
        }

        // renewalData['data'] = data;
        renewalData.push(data);
      }
    }

    // console.log(renewalData);

    // Make the request.
    this._renewUsages({'userIBAN': this.state.userIBAN, data: renewalData});
  };

  // When we have a token we can query all user usages.
  _getUsages = () => {
    let {userToken} = this.state;

    this.setState({
      statusMessage: 'querying_data',
      statusMessageType: 'waiting'
    });

    this._startAjax();

    // Make the API call and handle the response.
    GinestaApiCalls.getUserUsages(userToken)
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          this.setState(() => {
            let usages = response.data;

            if (usages.length > 0) {
              return {
                usages: usages,
                statusMessage: 'usages_found',
                statusMessageType: 'notice',
              };
            } else {
              return {
                usages: usages,
                statusMessage: 'no_usages_notice',
                statusMessageType: 'error',
              }
            }
          });
        }
      })
      .catch((response) => {
        console.log('Error ' + response);

        this.setState((state) => ({
          statusMessage: 'internal_error',
          statusMessageType: 'error',
        }));
      })
      .finally(() => {
        this._endAjax();
      });
  };

  // Called when the user has selected their update preferences and we want to post the data to the API.
  _renewUsages = renewalData => {
    let {userToken, cultureCode} = this.state;

    this._startAjax();

    // Make the API call and handle the response.
    GinestaApiCalls.renewUsages(userToken, renewalData, cultureCode)
      .then((response) => {
        // Show success message.
        this.setState((state) => ({
          statusMessage: 'prefences_updated',
          statusMessageType: 'success',
        }), this._nextStep);
      })
      .catch((response) => {
        this.setState((state) => ({
          statusMessage: 'internal_error',
          statusMessageType: 'error',
        }));

      })
      .finally(() => {
        this._endAjax();
      });
  }

  // Show the user something is happening.
  _startAjax = () => {
    this.setState({
      doingAjax: true
    });
  };

  // Show the user that the current action has completed.
  _endAjax = () => {
    this.setState({
      doingAjax: false
    });
  }

  // Move forward to next step in process.
  _nextStep = () => {
    this.setState((prevState) => {
      return {step: prevState.step + 1};
    });
  }

  // Move back a step.
  _prevStep = () => {
    this.setState((state) => {
      if (state.step - 1 >= 0) {
        return {step: state.step - 1};
      } else {
        return state;
      }
    }, () => {
      this._resetNoticeForStep();
    });
  }

  _getLangFromUrl = () => {
    let pathname = window.location.pathname;
    let regex = new RegExp(/^\/[a-zA-Z]{2}/);
    let matches = regex.exec(pathname);

    // Return the match without the slash.
    if (matches && matches[0]) {
      return matches[0].substring(1);
    } else {
      return null;
    }
  };

  _resetNoticeForStep = () => {
    switch (this.state.step) {
      case 0:
        this.setState({
          statusMessage: i18next.t('enter_code'),
          statusMessageType: ''
        })
        break;

      default: break;
    }
  }

  /**
   * Form input handlers.
   */
  // Tax code.
  onTaxStateChange = event => {
    let newValue = event.target.value;

    this.setState(state => ({
      userTaxCode: newValue
    }));
  }

  // Auth code / SMS code input
  onAuthCodeChange = event => {
    let newValue = event.target.value;

    this.setState(state => ({
      userAuthCode: newValue
    }));
  }

  // IBAN number on renewal form.
  onUserIBANChange = event => {
    let newValue = event.target.value;

    this.setState(state => ({
      userIBAN: newValue
    }));
  }

  switchLanguage = event => {
    event.preventDefault();

    let newLng = event.target.parentNode.getAttribute('data-lng');

    if (newLng) {
      i18next.changeLanguage(newLng, () => {
        // Force a re-render.
        this.forceUpdate();

        if (newLng && LanguageCodes[newLng]) {
          this.setState({
            cultureCode: LanguageCodes[newLng]
          });
        }
      });
    }
  }

  // Toggle the meny for mobile.
  toggleMenu = () => {
    this.setState((state) => {
      return {showMenu: ! state.showMenu};
    });
  }

  render() {
    let {
      statusMessage, 
      statusMessageType, 
      usages, 
      doingAjax, 
      step, 
      userIBAN,
      userHasNoUsages,
      showMenu
    } = this.state;

    let taxCodeFieldParams = {
      elementId: 'tax-code-lookup',
      name: 'tax-code-lookup',
      value: this.state.userTaxCode,
      label: i18next.t('your_tax_code'),
      onChangeHandler: this.onTaxStateChange
    };

    let authFieldParams = {
      elementId: 'auth-code',
      name: 'code',
      value: this.state.userAuthCode,
      label: i18next.t('sms_auth_code'),
      onChangeHandler: this.onAuthCodeChange
    };

    // Define current step in process
    let stepMarkup = null;

    switch (step) {
      // First step, request SMS code.
      case 0:
        stepMarkup =
          <div className='step-0'>
            <TextInput {...taxCodeFieldParams} />
            <NavigationButton onClickHandler={this.validateUser} doingAjax={doingAjax} label={i18next.t('next')} />
          </div>;
        break;

      // Second step, authentication.
      case 1:
        stepMarkup = 
          <div className='step-1'>
            <TextInput {...authFieldParams} />
            <NavigationButton onClickHandler={this._prevStep} doingAjax={doingAjax} label={i18next.t('previous')} />
            <NavigationButton onClickHandler={this.authenticateUser} doingAjax={doingAjax} label={i18next.t('next')} />
          </div>;
        break;

      // Third step, allow user to select renewal options.
      case 2:
        stepMarkup =
          <div className='step-2'>
            <UserUsages usages={usages} formSubmit={this.formSubmit} doingAjax={doingAjax} onUserIBANChange={this.onUserIBANChange} userIBAN={userIBAN} userHasNoUsages={userHasNoUsages} />
          </div>;
        break;

      // Last step. Confirmation message handled via the statusMessage prop.
      case 3: 
        stepMarkup = 
          <div className='step-3'>
            <img className='icon-check' src={check} alt='Complete' />
          </div>
        ;
        break;

      default: break;
    }

    return (
      <div className='ginesta-app' id='contenido-reservas'>
        <div id='top-bar'>
          <div className='top-bar-inner'>
            <LanguageSwitcher onLanguageSwitchHandler={this.switchLanguage} />
          </div>
        </div>
        <div className='main'>
          <Logo showMenu={showMenu} menuToggleHandler={this.toggleMenu} />
          <PrimaryNavigation showMenu={showMenu} />
          <Intro />
          <div className='ginesta-app__shell'>
            <div className={'ginesta-app__status-message ' + statusMessageType}>{i18next.t(statusMessage)}</div>
            {stepMarkup}
          </div>
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default App;