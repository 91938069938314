import React from 'react';

const TextInput = (props) => {
	let {name, value, onChangeHandler, label, elementId} = props;

	return (
		<p className="form-row">
			<label htmlFor={elementId}>{label}</label>
			<input id={elementId} className='form-input' type='text' name={name} value={value} onChange={onChangeHandler} />
		</p>
	);
}

export default TextInput;