import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './l18n/en';
import es from './l18n/es';
import ca from './l18n/ca';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en,
  es,
  ca,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ca',
    fallbackLng: 'ca',
    debug: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;