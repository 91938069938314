/**
 * Format number including currency
 * @param  {[type]} price [description]
 * @return {[type]}       [description]
 */
export const formatPrice = (price) => {
	return '€' + price.toFixed(2);
};

/**
 * Format a date
 * @param  {[type]} date [description]
 * @return {[type]}      [description]
 */
export const formatDate = (date) => {
	let d = new Date(date);

	// dd/mm/yy
	return (
		'0' + d.getDate()).slice(-2) + '/' 
		+ ('0' + (d.getMonth() + 1)).slice(-2) + '/' 
		+ d.getFullYear().toString().slice(-2);
};