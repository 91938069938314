import React from 'react';

const LanguageSwitcher = props => {
	let {onLanguageSwitchHandler} = props;

	return (
		<div className='language-switcher'>
			<a href="/?lng=ca" data-lng='ca' onClick={onLanguageSwitchHandler}><img src={process.env.PUBLIC_URL + '/img/cat.gif'} alt="ca-ES"/></a>
			<a href="/?lng=es" data-lng='es' onClick={onLanguageSwitchHandler}><img src={process.env.PUBLIC_URL + '/img/es.gif'} alt="es-ES"/></a>
			<a href="/?lng=en" data-lng='en' onClick={onLanguageSwitchHandler}><img src={process.env.PUBLIC_URL + '/img/en.gif'} alt="en-GB"/></a>
		</div>
	);
}

export default LanguageSwitcher;