export default {
  translation: {
    'next': 'Següent',
    'previous': 'Anterior',
    'please_wait': 'Si us plau espera',
    'querying_data': 'Si us plau, esperi un moment, estem consultant les seves dades.',
    'enter_code': 'Si us plau introdueix el seu DNI per començar',
    'your_tax_code': 'El seu DNI',
    'tax_code_not_found': 'Ho sentim, no vam poder trobar el seu DNI en el nostre sistema. Si us plau revisa i intenta de nou.',
    'tax_code_not_valid': 'Ho sentim, el seu DNI no és vàlid.',
    'sms_auth_code': 'Codi d\'autenticació SMS',
    'no_auth_code': 'Heu d\'introduir el seu codi d\'autenticació. Si no heu rebut el seu codi, esperi 5 minuts, sol·liciti un nou codi o contacti\'ns per obtenir ajuda.',
    'sms_sent': 'Hem enviat un codi d\'accés de validació a través d\'SMS. Si us plau introdueix el codi a continuació.',
    'sms_not_valid': 'El codi d\'autenticació de SMS no és vàlid.',
    'sms_code_expired': 'El codi d\'autenticació de SMS ha caducat. Si us plau, demani un nou codi.',
    'preferences_updated': 'Les seves preferències s\'han actualitzat amb èxit!',
    'internal_error': 'Error intern. Si us plau, poseu-vos en contacte amb nosaltres per obtenir ajuda.',
    // 'usages_found': 'Seleccioneu una opció per a cadascuna de les seves subscripcions actuals.',
    'no_usages_notice': 'Ho sentim, no vam poder trobar cap dada relacionada amb el seu compte. Si us plau, poseu-vos en contacte amb nosaltres per obtenir ajuda o intenti de nou.',
    'update_iban': 'Actualitza el seu IBAN (opcional)',
    'usages_found': 'Seleccioneu una opció per a cadascuna de les seves subscripcions actuals.',
    'usage_name': 'Nom',
    'usage_type': 'Tipus',
    'usage_area': 'Àrea',
    'usage_start_date': 'Data d\'inici',
    'usage_end_date': 'Data final',
    'usage_renewal_options_heading': 'Opcions de renovació',
    'usage_renewal_annual': 'Renovar per {{price}} per any',
    'usage_renewal_monthly': 'Renovar per {{price}} per mes',
    'usage_do_not_renew': 'Cancel (no renovar)',
    'iban_label': 'IBAN',
    'iban_heading': 'Número de compte bancari internacional (IBAN)',
    'update_button': 'Actualitzar informació',
    'prefences_updated': 'Gràcies, hem actualitzat el vostre compte.',
    'menu': 'Menú',
    'application_title': 'Renovació Port Ginesta',
    'application_description': 'Utilitzeu el formulari següent per renovar o cancel·lar els vostres contractes a Port Ginesta.',
    'menu_item_home_url': 'http://www.portginesta.com/',
    'menu_item_home': 'INICI',
    'menu_item_location_and_access_url': 'http://www.portginesta.com/situacio',
    'menu_item_location_and_access': 'SITUACIÓ I ACCESSOS',
    'menu_item_general_services_url': 'http://www.portginesta.com/serveis-generals',
    'menu_item_general_services': 'SERVEIS GENERALS',
    'menu_item_nautical_services_url': 'http://www.portginesta.com/serveis-nautics',
    'menu_item_nautical_services': 'SERVEIS NÀUTICS',
    'menu_item_leisure_url': 'http://www.portginesta.com/temps-oci',
    'menu_item_leisure': 'TEMPS D\'OCI',
    'menu_item_news_url': 'http://noticies.portginesta.com/',
    'menu_item_news': 'NOTÍCIES',
    'please_contact_the_port': 'Renovar (preu a consultar amb el port)',
  }
};