import React from 'react';

const FooterSection = () => {
	return (
		<div id="footer">
			<div id="logos">
				<img src={process.env.PUBLIC_URL + '/img/logo1.gif'} alt="AENOR EMPRESA REGISTRADA UNE-EN ISO 9001 - ER–0414/2015" title="AENOR EMPRESA REGISTRADA UNE-EN ISO 9001 - ER–0414/2015" />
				<img src={process.env.PUBLIC_URL + 'img/logo2.gif'} alt="AENOR SEGURIDAD Y SALUD LABORAL OHSAS 18001 - SST–013/2015" title="AENOR SEGURIDAD Y SALUD LABORAL OHSAS 18001 - SST–013/2015" />
				<img src={process.env.PUBLIC_URL + 'img/logo3.gif'} alt="AENOR GESTIÓN AMBIENTAL UNE-EN ISO 14001 – GA-2007/0491" title="AENOR GESTIÓN AMBIENTAL UNE-EN ISO 14001 – GA-2007/0491" />
				<img src={process.env.PUBLIC_URL + 'img/logo4.gif'} alt="EMAS GESTIÓ AMBIENTAL VERIFICADA E-CAT – 000249" title="EMAS GESTIÓ AMBIENTAL VERIFICADA E-CAT – 000249" />
				<img src={process.env.PUBLIC_URL + 'img/logo5.gif'} alt="IQNET CERTIFIED MANAGEMENT SYSTEM" title="IQNET CERTIFIED MANAGEMENT SYSTEM" />
				<img src={process.env.PUBLIC_URL + 'img/logo6.gif'} alt="MIEMBRO DE A.C.P.E.T." title="MIEMBRO DE A.C.P.E.T." />
			</div>
		  <div id="nav-bottom">
		    <div className="subnav-interior"><a href="avis-legal">Avís Legal</a></div><div className="navbottom_sep">|</div>
		    <div className="subnav-interior"><a href="http://www.clubmaritimportginesta.com" target="_blank" rel="noopener noreferrer">Club Marítim</a></div><div className="navbottom_sep">|</div>                 
		    <div className="subnav-interior"><img src={process.env.PUBLIC_URL + 'img/mail.gif'} alt="Email"/><a href="mailto:info@portginesta.com">Opina</a></div>
		  </div>
		  <div id="address">
		    <a href="mailto:info@portginesta.com">info@portginesta.com</a> / tel. +34 93664 3661 / +34 677 527 009
		  </div>
		  <div id="facebook">
		  	<a href="http://www.facebook.com/pages/Port-Ginesta/432598240148672" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + 'img/facebook.png'} alt="Facebook" /></a>
		  	<a href="https://www.instagram.com/portginestaoficial/" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + 'img/instagram.png'} alt="Instagram" /></a>
		  	<a href="https://www.youtube.com/user/PortGinestaBarcelona" target="_blank" rel="noopener noreferrer"><img src={process.env.PUBLIC_URL + 'img/youtube.png'} alt="Youtube" /></a>
		  </div>
		</div>
	);
}

export default FooterSection;