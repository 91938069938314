import React from 'react';
import toggle from '../assets/toggle.svg';

// l18n
import '../i18l';
import i18next from 'i18next';

/**
 * Logo and menu toggle.
 */
const Logo = ({showMenu, menuToggleHandler}) => {
	return (
		<div className='logo-container'>
			<div id="logo"><a href="http://www.portginesta.com/">PORT GINESTA</a></div>
			<div id="latitud-interior">l 41º 15'40 N &nbsp;&nbsp;  L 001º 55'20 E</div>
			<button className='menu-toggle' onClick={menuToggleHandler}><img className='icon' src={toggle} alt='Menu' /> {i18next.t('menu')}</button>
		</div>
	);
}

export default Logo;