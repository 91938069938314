export default {
  translation: {
    'next': 'Next',
    'previous': 'Back',
    'please_wait': 'Please wait',
    'querying_data': 'Please wait a moment, we are querying your data.',
    'enter_code': 'Please enter your ID (DNI) to begin',
    'your_tax_code': 'Your ID Code',
    'tax_code_not_found': 'Sorry, we could not find your ID in our system. Please check and try again.',
    'tax_code_not_valid': 'Sorry, your ID code is not valid.',
    'sms_auth_code': 'SMS authentication code',
    'no_auth_code': 'You must enter your authentication code. If you have not received your code, please wait 5 minutes then request a new code or contact us for help.',
    'sms_sent': 'We have sent a validation passcode via SMS. Please enter your code below.',
    'sms_not_valid': 'SMS authentication code not valid.',
    'sms_code_expired': 'SMS authentication code expired. Please request a new code.',
    'preferences_updated': 'Your preferences have been updated successfully!',
    'internal_error': 'Internal error. Please contact us for help.',
    // 'usages_found': 'Please select a value for each of your current subscriptions and (optionally) enter your IBAN number.',
    'no_usages_notice': 'Sorry, we could not find any data relating to your account. Please contact us for help or try again.',
    'update_iban': 'Update your IBAN (optional)',
    'usages_found': 'Please select an option for each of your current subscriptions.',
    'usage_name': 'Name',
    'usage_type': 'Type',
    'usage_area': 'Area',
    'usage_start_date': 'Start date',
    'usage_end_date': 'End date',
    'usage_renewal_options_heading': 'Renewal options',
    'usage_renewal_annual': 'Renew for {{price}} per year',
    'usage_renewal_monthly': 'Renew for {{price}} per month',
    'usage_do_not_renew': 'Cancel (do not renew)',
    'iban_label': 'IBAN',
    'iban_heading': 'International Bank Account Number (IBAN)',
    'update_button': 'Update Information',
    'prefences_updated': 'Thank you, we have updated your account.',
    'menu': 'Menu',
    'application_title': 'Port Ginesta Renewal',
    'application_description': 'Use the form below to renew or cancel your contract(s) at Port Ginesta.',
    'menu_item_home_url': 'http://www.portginesta.com/en',
    'menu_item_home': 'Home',
    'menu_item_location_and_access_url': 'http://www.portginesta.com/en/situation',
    'menu_item_location_and_access': 'Location And Access',
    'menu_item_general_services_url': 'http://www.portginesta.com/en/general-services',
    'menu_item_general_services': 'General Services',
    'menu_item_nautical_services_url': 'http://www.portginesta.com/en/nautical-services',
    'menu_item_nautical_services': 'Nautical Services',
    'menu_item_leisure_url': 'http://www.portginesta.com/en/leisure',
    'menu_item_leisure': 'Leisure',
    'menu_item_news_url': 'http://noticies.portginesta.com/',
    'menu_item_news': 'News',
    'please_contact_the_port': 'Renew (confirm price with the Port)',
  }
};