import React from 'react';

/**
 * Used to render a single form element for the renewal form.
 */
const SingleUsage = ({renewalId, priceId, label}) => {
	return (
		<label htmlFor={renewalId + '-' + priceId}>
			<span className='radio-label-text'>{label}</span>
			<input name={'item-' + renewalId} type='radio' value={priceId} id={renewalId + '-' + priceId} />
		</label>
	);
}

export default SingleUsage;