import React from 'react';

import '../i18l';
import i18next from 'i18next';

/**
 * A copy of the menu from the main website, with added translation for ca-ES and es-ES.
 */
const PrimaryNavigation = ({showMenu}) => {
	return (
		<div id="nav" className={showMenu ? 'show' : 'hide'}>
			<div className="nav-interior"><a href={i18next.t('menu_item_home_url')}>{i18next.t('menu_item_home')}</a></div>
			<div className="nav-interior"><a href={i18next.t('menu_item_location_and_access_url')}>{i18next.t('menu_item_location_and_access')}</a></div>
			<div className="nav-interior"><a href={i18next.t('menu_item_general_services_url')}>{i18next.t('menu_item_general_services')}</a></div>
			<div className="nav-interior"><a href={i18next.t('menu_item_nautical_services_url')}>{i18next.t('menu_item_nautical_services')}</a></div>
			<div className="nav-interior"><a href={i18next.t('menu_item_leisure_url')}>{i18next.t('menu_item_leisure')}</a></div>
			<div className="nav-interior"><a href={i18next.t('menu_item_news_url')}>{i18next.t('menu_item_news')}</a></div>
		</div>
	);
}

export default PrimaryNavigation;