import React, {Component} from 'react';
import TextInput from './TextInput';
import Usage from './Usage';
// import {formatPrice, formatDate} from './../helpers/Helpers';

// l18n
import '../i18l';
import i18next from 'i18next';

/**
 * Display a form which contains all user "usages" and options to cancel or renew those items.
 */
class UserUsages extends Component {
	constructor(props) {
		super(props);

		this.state = {
			totalSelected: 0,
		};
	}

	// Called when the form is interacted with. The submit button is hidden until the form
	// is completely filled out.
	checkAllOptionsValid = event => {
		let formElement = event.currentTarget,
				accumulator = 0;

		// Check how many options have been selected.
    for (let i = 0, l = formElement.length; i < l; i++) {
      let formInput = formElement[i];

      if (formInput.checked) {
        accumulator++;
      }
    }
    
    // Check if one option have been selected for each item to be renewed.
		this.setState(state => ({
			totalSelected: accumulator
		}));  	
	};

	render() {
		let {usages, formSubmit, doingAjax, onUserIBANChange, userIBAN} = this.props;
		let {totalSelected} = this.state;

		return (
			<form className='usages' onSubmit={formSubmit} onChange={this.checkAllOptionsValid} disabled={doingAjax}>
				{usages.map((value) => {
					let data = {
						area: value.Area,
						id: value.Id,
						name: value.Name,
						startDate: value.StartDate, 
						type: value.Type,
					};

					if (value.EndDate) {
						data.endDate = value.EndDate;
					}

					if (value.PrimaryPrice) {
						data.primary = {
							amount: value.PrimaryPrice.Amount,
							id: value.PrimaryPrice.Id
						};
					}

					if (value.SecondaryPrice) {
						data.secondary = {
							amount: value.SecondaryPrice.Amount,
							id: value.SecondaryPrice.Id
						};
					}

					return (
						<Usage key={data.id} data={data} />
					);
				})}
				{/* IBAN number. Allow the user to update their IBAN if they want to. */}
				{usages.length > 0 && 
					<div className='form-section'> 
						<p>{i18next.t('update_iban')}</p>
						<TextInput name='userIBAN' value={userIBAN} onChangeHandler={onUserIBANChange} label={i18next.t('iban_label')} elementId='user-iban' />
					</div>
				}
				{/* Only display the submit button if the user has input all renewal preferences. */}
				{(totalSelected === usages.length && usages.length > 0) && <input type='submit' value={i18next.t('update_button')} className='btn btn-submit' disabled={doingAjax} />}
			</form>
		);
	};
}

export default UserUsages;