import axios from 'axios'; // API request service.

// API service key.
const serviceKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaXRlSWQiOiIxNCIsIlNlcnZpY2VJZCI6IlVzYWdlUmVuZXcifQ.Yr4pTGXV_kvKCNzpdZOnawUmX1AoJZf-HfNifQbcXuQ';
const devServiceKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaXRlSWQiOiIxIiwiU2VydmljZUlkIjoiVXNhZ2VSZW5ldyJ9.Fo0oSxsmO6pcrT3co9AagXaFm64YPytIePAXpSxPkEs';

// API base URL.
const baseUrl = 'https://ideris-services.azure-api.net/usageRenew/';
const devUrl = 'https://ideris-services.azure-api.net/usageRenew_pre/';

// Endpoints
const endpoints = {
  validateUser: '/Authentication/ValidateUser',
  authenticateUser: '/Authentication/AuthenticateUser',
  getUserUsages: '/Usages/GetUserUsages',
  renewUsages: '/Usages/RenewUsages'
};

/*
Test emails

marc.zurriaga@raona.com
eva.aymami@raona.com
danransome@gmail.com

 */

export const GinestaApiCalls = {
  //testEmail: 'eva.aymami@raona.com', // Email used for testing instead of SMS code. Please update this to your email for testing.
  sendMail: true, // Should the user receive a confirmation email. Leave this set to FALSE for the time being.

  // Request to validate a user
  validateUser: function(event, taxCode) {
    event.preventDefault();

    // Return the promise.
    return axios({
      method: 'post',
      baseURL: baseUrl,
      url: endpoints.validateUser,
      params: {
        taxCode: taxCode,
      },
      headers: {
        'Service': serviceKey/*,
        'TestEmail': this.testEmail,*/
      }
    });
  },
  // Authenticate a user by means of the SMS code.
  authenticateUser: function(event, taxCode, passCode) {
    event.preventDefault();

    // Return the promise.
    return axios({
      method: 'post',
      baseURL: baseUrl,
      url: endpoints.authenticateUser,
      params: {
        taxCode: taxCode,
        passCode: passCode
      },
      headers: {
        'Service': serviceKey
      }
    });    
  },
  // Authenticate a user by means of the SMS code.
  getUserUsages: function(token) {
    // Return the promise.
    return axios({
      method: 'get',
      baseURL: baseUrl,
      url: endpoints.getUserUsages,
      priceWithRelatedConcepts: false,
      fullPeriodPriceAmount: false,
      headers: {
        'Service': serviceKey,
        'Authorization': token
      }
    });    
  },
  // Send data to renew or cancel current usages.
  renewUsages: function(token, renewalData, cultureCode) {
    let data = {
        UsageRenewals: renewalData.data,
    };

    // Send the updated IBAN if there is a value.
    if (renewalData.userIBAN !== '') {
      data.UserIBAN = renewalData.userIBAN;
    }

    // Return the promise.
    return axios({
      method: 'post',
      baseURL: baseUrl,
      url: endpoints.renewUsages,
      params: {
        sendMail: this.sendMail, 
        cultureCode: cultureCode, // ISO language code
      },
      data: data, // This is the request body.
      headers: {
        'Service': serviceKey,
        'Authorization': token
      }
    });  
  }
};